import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Spin from 'antd/lib/spin'
import Form from 'antd/lib/form'
import notify from 'utils/notify'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlusSquare } from 'react-icons/fa'
import { Fuel } from 'assets/svg'
import Tooltip from 'antd/lib/tooltip'
import Box from 'components/Box/BoxGeneric'
import NumberInput from 'components/Form/NumerInput'
import { TextPrimaryLarge } from 'components/Text/TextPrimary'
import { ButtonSecondaryDefault } from 'components/Button/ButtonSecondary'
import { clearRate, requestSaveQuote, saveCustomerRate } from 'store/duck/saveQuote.duck'
import { clearGetRate } from 'store/duck/getRate.duck'
import { clearSpecialServices } from 'store/duck/saveSpecialServices.duck'
import { clearGetZipHere, finishRate } from 'store/duck/getZipHere.duck'
import { clearCreateRate } from 'store/duck/createRate.duck'
import { saveSendEmail } from 'store/duck/sendEmail.duck'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import Typography from 'antd/lib/typography'
import { requestDeleteRate } from 'store/duck/deleteRate.duck'
import { requestCreateSuggestion, clearCreateSuggestion } from 'store/duck/createSuggestion.duck'
import { resetEditSuggestion } from 'store/duck/editSuggestion.duck'
import ModalFeedback from 'components/Menu/ModalFeedback'
import { StyledSubtitle } from './styles'
import { convertToCurrency, convertToNumber } from './utils'
import CustomerRateLite from './CustomerRateLite'



const { Text } = Typography

const CustomerRate = () => {

  const { singleCustomer, saveQuote, carrierRate, rateBasic, datRate, gsDatalake, role, saveFeedback, ignoreSaveQuote, successGetRate, fetchingRate } = useSelector((state) => ({
    singleCustomer: state.getCustomers.singleCustomer,
    saveQuote: state.saveQuote,
    carrierRate: state.getRate,
    rateBasic: state.getRate,
    datRate: state.getRate.rate?.base_rate?.datRate,
    gsDatalake: state.getRate.rate?.internal_adjustment,
    role: state.login.data.roleId ? state.login.data.roleId.name : '',
    saveFeedback: state.createSuggestion,
    ignoreSaveQuote: state.saveQuote.ignore,
    successGetRate: state.getRate.success,
    fetchingRate: state.getRate.fetching
  }))
  const location = useLocation()
  const [flat, setFlat] = useState(0)
  const [userSave, setUserSave] = useState(true)
  const userSaveData = useRef(userSave)
  const [margin, setMargin] = useState(0)
  const rateCost = convertToNumber(carrierRate?.rate?.rateCost ?? '')
  const [totalRate, setTotalRate] = useState(0)
  const [suggestion, setSuggestion] = useState({ requested: false, sentiment: "" })
  const [feedbackModal, setFeedbackModal] = useState(false)
  const [feedbackReady, setFeedbackReady] = useState(false);
  const [feedbackId, setFeedbackId] = useState("");
  const [triggerSave, setTriggerSave] = useState(false)

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [lh, setlh] = useState(null)


  const validRateCost = JSON.stringify(carrierRate?.rate) !== "{}" && carrierRate?.rate?.rateCost !== undefined

  const validDiscount = () => {
    return rateBasic?.rate?.customFuelPrice && rateBasic?.rate?.customFuelPrice.discount
  }
  const idRateSelected = location?.state?.id

  useEffect(() => {
    if (saveFeedback.data?._id && feedbackReady) {
      setFeedbackId(saveFeedback.data?._id)
      dispatch(clearCreateSuggestion())
      setFeedbackModal(true)
    }
  }, [dispatch, saveFeedback, feedbackReady, setFeedbackModal])

  useEffect(() => {
    if (singleCustomer?.consider) {
      if (rateBasic?.rate?.customFuelPrice && rateBasic.rate?.customFuelPrice?.discount)
        setlh(Math.ceil(totalRate - rateBasic?.rate?.customFuelPrice.discount))
    } else {
      setlh(null)
    }
  }, [totalRate, singleCustomer, rateBasic])

  useEffect(() => {
    setUserSave(true)
  }, [rateBasic])

  useEffect(() => {
    if (idRateSelected) {
      dispatch(clearCreateRate())
    }
    if (saveQuote.success && userSaveData && ignoreSaveQuote === false && role !== "Lite") {
      dispatch(clearCreateRate())
      dispatch(clearGetZipHere())
      dispatch(finishRate())
      dispatch(clearSpecialServices())
      dispatch(clearGetRate())
      notify('success', 'Rate saved successfully')
      if (saveQuote.success && suggestion.requested && role !== "Lite") {
        dispatch(requestCreateSuggestion({ quote: saveQuote?.data?._id, sentiment: suggestion.sentiment, suggestion: " - " }))
        setSuggestion({ requested: false, sentiment: "" })
      }
      return
    }
    if (saveQuote.success && suggestion.requested && role !== "Lite") {
      dispatch(requestDeleteRate(saveQuote.data?._id))
      dispatch(requestCreateSuggestion({ quote: saveQuote?.data?._id, sentiment: suggestion.sentiment, suggestion: " - " }))
      setSuggestion({ requested: false, sentiment: "" })
      return
    }
    if (saveQuote.error) {
      notify('error', `Error saving rate!: ${saveQuote.message}`)
      dispatch(clearRate())
    }
  }, [dispatch, saveQuote, idRateSelected, suggestion, ignoreSaveQuote, role])

  useEffect(() => {
    if (carrierRate.success === false) return
    const rate = Number(carrierRate.rate.rateCost.split('.')[0].replace(/[^0-9.-]+/g, ''))
    const total = rate + rate * margin + flat
    setTotalRate(total)
    const save = { rateCost: total }
    if (singleCustomer?.consider) {
      save.LH = lh
    }
    dispatch(saveSendEmail(save))
  }, [carrierRate.rate.rateCost, margin, flat, carrierRate.success, dispatch, lh, singleCustomer])

  useEffect(() => {
    if (singleCustomer?.flat === undefined) return
    const loadedFlat = singleCustomer?.flat
    const loadedMargin = singleCustomer?.margin
    setFlat(loadedFlat)
    setMargin(loadedMargin / 100)
    form.setFieldsValue({ margin: loadedMargin, flat: loadedFlat })
  }, [form, singleCustomer])

  useEffect(() => {
    if (flat >= 10000) {
      form.setFieldsValue({ flat: 10000 })
    } else if (flat <= -10000) {
      setFlat(-10000)
      form.setFieldsValue({ flat: -10000 })
    }
  }, [flat, form])

  const handleCloseFeedback = (open) => {
    setFeedbackModal(open)
    setFeedbackReady(false)
    setFeedbackId("")
    dispatch(clearCreateSuggestion())
    dispatch(resetEditSuggestion())
  }

  const handleValuesChange = (changedValues) => {
    if (changedValues.margin) setMargin(Number(changedValues.margin) / 100)
    if (changedValues.flat) setFlat(Number(changedValues.flat))
  }


  const handleFinish = useCallback(
    (ignore = false) => {
      const finalRate = Math.round(
        Number(carrierRate.rate.rateCost.split('.')[0].replace(/[^0-9.-]+/g, '')) +
        Number(carrierRate.rate.rateCost.split('.')[0].replace(/[^0-9.-]+/g, '')) * margin +
        flat
      ).toFixed(32)
      dispatch(saveCustomerRate(flat, margin, finalRate))
      const quote = { ...saveQuote?.data }
      if ("_id" in quote) {
        delete quote._id;
      }
      if (!quote.customer) {
        quote.customer = singleCustomer?.id
      }

      quote.LH = lh
      const saveRouteInfo = { ...carrierRate?.rate?.routeInfo }
      if (saveRouteInfo.routes) {
        delete saveRouteInfo.routes
      }
      dispatch(
        requestSaveQuote({
          ...quote,
          udScore: carrierRate?.rate?.udScore,
          customerRate: { margin, flat },
          rate: finalRate,
          details: {
            base_rate: carrierRate.rate.base_rate,
            internal_adjustment: carrierRate.rate.internal_adjustment,
            priceBase: carrierRate.rate.priceBase,
            rateCost: carrierRate.rate.rateCost,
            ruleAdjustment: carrierRate.rate.ruleAdjustment ?? [],
            multiStepAdjustment: carrierRate.rate.multiStepAdjustment ?? [],
            priceSpecialServices: carrierRate?.rate?.priceSpecialServices ?? '',
            routeInfo: saveRouteInfo ?? {},
            maximumMarkdown: carrierRate?.rate?.maximumMarkdown,
            marketConditions: carrierRate?.rate?.marketConditions,
            udScore: carrierRate?.rate?.udScore,
            ltrOrigin: carrierRate?.rate?.ltrOrigin,
            ltrDestination: carrierRate?.rate?.ltrDestination,
            forecast: carrierRate?.rate?.forecast,
          },

          customerRateInfo: { flat: singleCustomer?.flat, margin: singleCustomer?.margin },
        }, ignore)
      )
    },
    [dispatch, carrierRate, flat, lh, margin, saveQuote, singleCustomer],
  )

 


  const handleSuggestion = (sentiment) => {
    setUserSave(false)
    handleFinish(true)
    setSuggestion({ requested: true, sentiment })
    setFeedbackReady(true)
  }

  const displayFuel = () => {
    const { success: succesRate } = carrierRate
    if (!succesRate) {
      return null
    }
    const { customFuelPrice } = rateBasic
    if (gsDatalake && gsDatalake?.rate) {
      if (gsDatalake?.rate !== '$0.00') {
        return (
          <Tooltip
            placement="bottom"
            title={
              <span style={{ color: '#545454' }}>
                <Text style={{ fontStyle: 'italic', fontWeight: 600 }}>Fuel:</Text>
                <Text style={{ fontStyle: 'italic' }}>{` $${customFuelPrice?.price}`}</Text>
              </span>
            }
            color="#D9D9D9"
          >
            <img
              alt=""
              style={{ marginLeft: '3px', width: '16px', height: '16px', paddingBottom: '1px' }}
              src={Fuel}
            />
          </Tooltip>
        )
      }
    }
    if (datRate) {
      if (datRate?.datLow?.rate === '$0.00') {
        return (
          <Tooltip
            placement="bottom"
            title={
              <span style={{ color: '#545454' }}>
                <Text style={{ fontStyle: 'italic', fontWeight: 600 }}>Fuel:</Text>
                <Text style={{ fontStyle: 'italic' }}>{` $${customFuelPrice?.price}`}</Text>
              </span>
            }
            color="#D9D9D9"
          >
            <img
              alt=""
              style={{ marginLeft: '3px', width: '16px', height: '16px', paddingBottom: '1px' }}
              src={Fuel}
            />
          </Tooltip>
        )
      }
    }
    return null
  }


  useEffect(() => {
    if (role === "Lite" && successGetRate) {
      if (!triggerSave) {
        handleFinish(true)
        setTriggerSave(true) 
      }
    }

    if (triggerSave && fetchingRate) {
      setTriggerSave(false) 
    }

  }, [dispatch, role, successGetRate, handleFinish, triggerSave, fetchingRate])

  



  if (role === "Lite") {
    return <CustomerRateLite
      fetching={carrierRate.fetching}
      validRateCost={validRateCost}
      totalRate={totalRate}
    />
  }

  return (
    <>
      <ModalFeedback visible={feedbackModal} close={handleCloseFeedback} SuggestionId={feedbackId} />
      <Spin spinning={carrierRate.fetching}>
        <Box title="Rate Summary" height="auto" backgroundColor bordertop>
          <Form
            layout="vertical"
            name="customerRate"
            form={form}
            onFinish={() => {
              setUserSave(true)
              handleFinish()
            }}
            onValuesChange={handleValuesChange}
            hideRequiredMark
          >
            {location?.state?.id && (
              <StyledSubtitle className="subtitle">
                Rate #<span className="subtitleRate">{location.state?.id}</span>
              </StyledSubtitle>
            )}
            <Row gutter={[0, 12]}>
              <Col span={10}>
                <NumberInput
                  name="flat"
                  label="Flat Markup"
                  floatLabel
                  rules={[
                    { required: true, message: 'this field is required' },
                    {
                      type: 'number',
                      max: 10000,
                      message: 'The Base Markup should not be greater than 10000',
                      transform: Number,
                    },
                    {
                      type: 'number',
                      min: -10000,
                      message: 'The Base Markup should not be less than -10000',
                      transform: Number,
                    },
                  ]}
                  prefix="$"
                />
              </Col>
              <Col span={4}>
                <div style={{ marginTop: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  <FaPlusSquare size="1.5rem" style={{ color: '#002555' }} />
                </div>
              </Col>
              <Col span={10}>
                <NumberInput
                  name="margin"
                  label="Percentage Markup"
                  floatLabel
                  rules={[
                    { required: true, message: 'this field is required' },
                    {
                      type: 'number',
                      max: 100,
                      message: 'The Percentage markup not be greater than 100',
                      transform: Number,
                    },
                    {
                      type: 'number',
                      message: 'The Percentage markup not be less than 0',
                      transform: Number,
                    },
                  ]}
                  suffix="%"
                  step={1}
                />
              </Col>
              <Row gutter={[0, 7]}>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <TextPrimaryLarge>Est. Carrier Cost</TextPrimaryLarge>
                    </Col>

                    <Col>
                      <TextPrimaryLarge>
                        {validRateCost ? `$${convertToCurrency(carrierRate.rate.rateCost)}` : 'N/A'}
                      </TextPrimaryLarge>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} data-testid="custom-customerRate">
                  <Row justify="space-between">
                    <Col>
                      <TextPrimaryLarge>Customer Rate</TextPrimaryLarge>
                    </Col>
                    <Col>
                      <Row justify="end">
                        <TextPrimaryLarge style={validRateCost ? { fontWeight: 'bold' } : {}}>
                          {validRateCost ? `$${convertToCurrency(totalRate)}` : 'N/A'}
                        </TextPrimaryLarge>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginBottom: '2px',
                        }}
                      >
                        <TextPrimaryLarge style={{ fontSize: '13px', color: 'black' }}>
                          {singleCustomer?.consider && validDiscount() ? `$${lh} (LH)` : ''}
                        </TextPrimaryLarge>
                        {singleCustomer?.consider && validDiscount() ? displayFuel() : null}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <TextPrimaryLarge>Margin ($)</TextPrimaryLarge>
                    </Col>
                    <Col>
                      <TextPrimaryLarge>
                        {validRateCost
                          ? `$${convertToCurrency(
                            (convertToNumber(carrierRate.rate.rateCost) * margin + flat).toFixed(2)
                          )}`
                          : 'N/A'}
                      </TextPrimaryLarge>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} data-testid="custom-Percentage">
                  <Row justify="space-between">
                    <Col>
                      <TextPrimaryLarge>Percentage (%)</TextPrimaryLarge>
                    </Col>
                    <Col>
                      <TextPrimaryLarge>
                        {validRateCost ? `${(((totalRate - rateCost) / rateCost) * 100).toFixed(1)}%` : 'N/A'}
                      </TextPrimaryLarge>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row justify="end" style={{ marginTop: 20 }} gutter={16}>
              {validRateCost && userSave && (<>
                <Col span={8}>
                  <ButtonPrimary
                    height="40px"
                    onClick={() => handleSuggestion('Too High')}
                  >
                    Rate is high
                  </ButtonPrimary>
                </Col>
                <Col span={8}>
                  <ButtonPrimary
                    height="40px"
                    onClick={() => handleSuggestion('Too Low')}
                  >
                    Rate is low
                  </ButtonPrimary>
                </Col>
              </>)}
              <Col span={8}>
                <ButtonSecondaryDefault
                  type="primary"
                  form="customerRate"
                  htmlType="submit"
                  block
                  disabled={!carrierRate.success}
                >
                  Save
                </ButtonSecondaryDefault>
              </Col>
            </Row>
          </Form>
        </Box>
      </Spin>
    </>
  )
}

export default CustomerRate
