import React from 'react'
import Spin from 'antd/lib/spin'
import Box from 'components/Box/BoxGeneric'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import { TextPrimaryLarge } from 'components/Text/TextPrimary'
import PropTypes from 'prop-types'
import { convertToCurrency } from './utils'

export default function CustomerRateLite({ fetching = false, validRateCost = false, totalRate = 0 }) {
    return (
        <React.Fragment key="fragment-customerRate">
            <Spin spinning={fetching}>
                <Box title="Rate Summary" height="auto" backgroundColor bordertop>
                    <Col span={24}  >
                        <Row gutter={[48, 8]}>
                            <Col span={12}>
                                <TextPrimaryLarge>Customer Rate</TextPrimaryLarge>
                            </Col>
                            <Col span={12} >
                                <TextPrimaryLarge style={validRateCost ? { fontWeight: 'bold' } : {}}>
                                    {validRateCost ? `$${convertToCurrency(totalRate)}` : 'N/A'}
                                </TextPrimaryLarge>
                            </Col>
                        </Row>
                    </Col>
                </Box>
            </Spin>
        </React.Fragment>
    )
}

CustomerRateLite.propTypes = {
    fetching: PropTypes.bool.isRequired,
    validRateCost: PropTypes.bool.isRequired,
    totalRate: PropTypes.number.isRequired
}

