export const convertToNumber = (string) => Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed())

export const convertToCurrency = (value) => {
    if (typeof value !== 'number') {
        return convertToNumber(value)
            .toFixed()
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
    return value.toFixed().replace(/\d(?=(\d{3})+\.)/g, '$&,')
}
