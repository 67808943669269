import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { debounce } from 'lodash'
import ModalRates from 'components/ModalRates/ModalRates'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearGetRate } from 'store/duck/getRate.duck'
import { requestGetAllRates } from 'store/duck/getAllRates.duck'
import { clearDownloadRates } from 'store/duck/getDownloadMultipleRates.duck'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { clearDeleteRate, requestDeleteRate } from 'store/duck/deleteRate.duck'
import { FormItem } from 'components/Input'
import Table from 'components/Table'
import FloatLabel from 'components/Label/FloatLabel'
import StyledSearch from 'components/Input/Search'
import DropdownApiInput from 'components/Form/DropdownApiInput'
import RulesContainer from 'containers/rules/styles'
import { MdFileDownload } from 'react-icons/md'
import notify from 'utils/notify'
import ModalQuote from 'components/ModalQuote'
import { unloadModalRate } from 'store/duck/getModalRate.duck'
import { formatParamsSearchLane } from 'utils/formatValues'
import { requestGetStates } from 'store/duck/getStates.duck'
import CustomSelect from 'components/CustomSelect'
import { requestGetAllCustomer } from 'store/duck/getAllCustomer.duck'
import useCustomersFromUser from 'hooks/useCustomersFromUser'
import { requestSaveSelectedCustomer } from 'store/duck/saveSelectedCustomer.duck'
import DateRange from 'components/Form/DateRange'
import DateInput from 'components/Form/DateInput'
import columns from './utils/TableColumns'
import downloadRates from './utils/downloadRates'
import BidStatusFilter from './utils/BidStatusFilter'


const HistoryLanes = ({ addDatRate = false }) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { loadWon } = form.getFieldsValue() || {}
  const { xs } = useBreakpoint()
  const dispatch = useDispatch()
  const { rates, error, totalRecords, fetching: fetchingQuotes } = useSelector((state) => state.getAllRates)
  const download = useSelector((state) => state.getDownloadMultipleRates)
  const { message, error: errorDelete, successDelete } = useSelector((state) => state.deleteRate)
  const { data, fetching } = useSelector((state) => state.getStates)
  const { error: errorLoadWon, message: messageLoadWon } = useSelector((state) => state.patchQuote)
  const isBulkDeleteRef = useRef(null)
  const [totalRates, setTotalRates] = useState(rates)
  const [params, setParams] = useState({ page: 1 })
  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)
  const {
    error: errorRate,
    message: messageErroRate,
    fetching: fetchingRate,
    success
  } = useSelector((state) => state.getRate)
  const { rateSelected } = useSelector((state) => state.createRate)
  const [visible, setvisible] = useState(false)
  const { isModalRate, modalRate } = useSelector((state) => state.createModalRate)
  const [displayModalRate, setDisplayModalRate] = useState(false)
  const { data: customers } = useSelector((state) => state.getAllCustomer)
  const [dropdownData, setRequest] = useCustomersFromUser()
  const [customerOptions, setCustomerOptions] = useState([])
  const { selectedCustomer } = useSelector((state) => state.saveSelectedCustomer)

  const handleRow = (index = "", fetchingData = false, updateLoad = false) => {
    if (index !== "") {
      const copyRateData = [...totalRates]
      let newRate = { ...copyRateData[index], isFetching: !fetchingData }
      if (updateLoad) {
        newRate = { ...newRate, loadWon: !copyRateData[index]?.loadWon }
      }
      copyRateData[index] = newRate
      setTotalRates(copyRateData)
    }
  }

  useEffect(() => {
    setCustomerOptions([])
  }, [])

  useEffect(() => {
    if (addDatRate && dropdownData.length === 0) {
      setRequest(addDatRate)
    }
  }, [addDatRate, setRequest, dropdownData])

  useEffect(() => {
    if (addDatRate && dropdownData.length > 0) {
      setCustomerOptions(dropdownData)
      const newParams = { ...params, customer: [] }
      if (!params.customer) {
        if (selectedCustomer !== "") {
          setParams({ ...params, customer: [selectedCustomer] })
          form.setFieldsValue({ customer: selectedCustomer })
        } else {
          dropdownData.forEach((element) => {
            newParams.customer.push(element.id)
          })
          setParams(newParams)
        }
      }
    } else {
      setCustomerOptions(customers)
    }
    if (!addDatRate && customers.length > 0) {
      setCustomerOptions(customers)
    }
  }, [dropdownData, addDatRate, customers, params, selectedCustomer, form])

  const onCloseModalRate = useCallback(() => {
    dispatch(unloadModalRate())
    setDisplayModalRate(false)
  }, [dispatch])

  useEffect(() => {
    if (isModalRate) {
      if (modalRate.details && modalRate.details.base_rate) {
        setDisplayModalRate(true)
      } else notify('error', 'No information available')
      dispatch(unloadModalRate())
    }
  }, [isModalRate, modalRate, dispatch])

  useEffect(() => {
    if (fetchingRate) {
      setvisible(true)

    }
    if (success) {
      setvisible(false)
      const id = rateSelected?._id
      const customer = rateSelected?.customer
      if (id && customer) {
        history.push({
          pathname: '/dashboard',
          state: { id, customer },
        })
      }
    }
    if (errorRate) {
      setvisible(false)
      notify('error', messageErroRate)
      dispatch(clearGetRate())
    }
  }, [fetchingRate, messageErroRate, errorRate, dispatch, setvisible, success, rateSelected, history])
  
  const setSearch = useCallback(
    debounce((textToSearch) => setParams(textToSearch), 400),
    [setParams]
  )

  const handleValuesChange = (_, allValues) => {

    if (params?.limit) allValues.limit = params?.limit
    if (params?.page) allValues.page = params?.page
    const filterParamas = { loadWon: allValues?.loadWon, ...formatParamsSearchLane(allValues) }
    const customerValue = allValues?.customer
    if (customerValue) {
      if (customerValue !== selectedCustomer) dispatch(requestSaveSelectedCustomer(allValues?.customer))
    } else if (selectedCustomer !== "") {
      dispatch(requestSaveSelectedCustomer(""))
    }
    setSearch(filterParamas)
  }

  useEffect(() => {
    dispatch(requestGetStates(''))
    if (!addDatRate) {
      dispatch(requestGetAllCustomer({ active: true }))
    }
  }, [dispatch, addDatRate])

  useEffect(() => {
    if (loadWon === undefined) {
      form.setFieldsValue({ loadWon: '' })
    }
    const triggerParams = {
      ...params,
      loadWon: loadWon ?? '',
      createdAt: params?.createdAt ? moment(params.createdAt).format('YYYY-MM-DD') : undefined,
    }
    dispatch(requestGetAllRates(triggerParams))

  }, [dispatch, params, form, loadWon, dropdownData, addDatRate])

  useEffect(() => {
    if (errorLoadWon) {
      notify('error', messageLoadWon)
      if (loadWon === undefined) {
        form.setFieldsValue({ loadWon: '' })
      }
      dispatch(
        requestGetAllRates({
          ...params,
          loadWon: loadWon ?? '',
          createdAt: params.createdAt ? moment(params.createdAt).format('YYYY-MM-DD') : undefined,
          // pickupDate: params.pickupDate ? moment(params.pickupDate).format('YYYY-MM-DD') : undefined,
        })
      )
    }
  }, [dispatch, params, errorLoadWon, messageLoadWon, form, loadWon])

  useEffect(() => {
    if (successDelete) {
      dispatch(requestGetAllRates(params))
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
    }
    if (errorDelete) {
      notify('error', message)
    }
    dispatch(clearDeleteRate())
  }, [dispatch, params, successDelete, msgSnack, message, errorDelete])

  useEffect(() => {
    setTotalRates(rates)
  }, [rates])

  useEffect(() => {
    const date = new Date()
    if (!download.success) return
    const url = window.URL.createObjectURL(new Blob([download.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `MultipleRates_${date}.csv`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
    dispatch(clearDownloadRates())
  }, [download, dispatch])

  useEffect(() => {
    if (error) {
      notify('error', 'Error getting lane history!')
    }
  }, [error])

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const [expandedRowKey, setExpandedKey] = useState('')

  const onExpand = (key) => {
    setExpandedKey((prev) => {
      const newKey = key._id
      return prev !== newKey ? newKey : ''
    })
  }

  useEffect(() => {
    if (successDelete) {
      if (isBulkDeleteRef) {
        isBulkDeleteRef.current = false
      } else {
        notify('success', 'Rate has been deleted')
      }

      dispatch(clearDeleteRate())
      dispatch(requestGetAllRates(params))
    }
  }, [dispatch, params, successDelete])


  return (
    <RulesContainer>
      <ModalQuote visible={displayModalRate} onCancel={onCloseModalRate} data={modalRate} />
      <ModalRates visible={visible} messageBody="Please wait while the new rate is applied on the Dashboard!" />
      <Form
        layout="vertical"
        name="filter-history-lanes"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        onValuesChange={handleValuesChange}
        hideRequiredMark
        data-testid="form-filters-lane"
      >
        <Row gutter={[24, 16]}>
          <Col span={addDatRate ? 4 : 7} xs={xs ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col span={4} xs={xs ? 24 : undefined}>
            <FloatLabel label="Customer" show>
              <FormItem name="customer">
                <CustomSelect
                  allowClear
                  showSearch
                  placeholder=""
                  keysort="label"
                  size="large"
                  options={(customerOptions ?? []).map((item) => ({ value: item.id, label: item.value }))}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </FormItem>
            </FloatLabel>
          </Col>

          <Col span={4} xs={xs ? 24 : undefined}>
            <DropdownApiInput
              label="Origin"
              formProps={{
                name: 'originState',
              }}
              inputProps={{
                options: fetching ? [] : data.map((state) => ({ value: state?.abbreviation, label: state.label })),
                keysort: 'label',
                filterOption: (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              loading={fetching}
            />
          </Col>
          <Col span={4} xs={xs ? 24 : undefined}>
            <DropdownApiInput
              label="Destination"
              formProps={{
                name: 'destinationState',
              }}
              inputProps={{
                options: fetching ? [] : data.map((state) => ({ value: state?.abbreviation, label: state.label })),
                keysort: 'label',
                filterOption: (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              loading={fetching}
              avoidDebounce
            />
          </Col>
          {
            addDatRate && (
              <Col span={3}>
                <DateRange label="Pickup Date" name="pickupDate" />
              </Col>
            )
          }


          <Col span={2} xs={xs ? 24 : undefined}>
            <BidStatusFilter />
          </Col>

          {!addDatRate && (
            <Col span={3} xs={xs ? 24 : undefined}>
              <DateInput label="Creation Date" name="createdAt" />
            </Col>
          )}
          {
            addDatRate && (
              <Col span={3}>
                <DateRange label="Creation Date" name="createdAt" />
              </Col>
            )
          }

        </Row>
      </Form>
      <Table
        dispatch={dispatch}
        columns={columns(addDatRate, handleRow)}
        rowSelection
        data={totalRates}
        loading={fetchingQuotes}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        updatedPagination
        pagination={{ total: totalRecords }}
        limitPerPage={100}
        paginationOptions={['100', '250', '500', '1000']}
        entityNameForBulk="Rate"
        entityNameForTextOnTopOfTableForBulk="lane history"
        onChange={handleTableChange}
        onExpand={onExpand}
        alignRowTable="50px"
        expandedRowKeys={expandedRowKey}
        onRow={(_) => {
          return [expandedRowKey].includes(_._id) && { className: 'expand-parent' }
        }}
        expandedRowRender={(record) => {
          return (
            <Row>
              <Col span={5} style={{ marginLeft: '50.5%' }}>
                {record.stops.map((stop, index) => (
                  <div key={index}>{stop.origin.city}</div>
                ))}
                <div>{record.stops[record.stops.length - 1].destination.city}</div>
              </Col>
            </Row>
          )
        }}
        pauseProps={{
          label: 'Download',
          labelSuccess: 'downloaded',
          // eslint-disable-next-line react/jsx-props-no-spreading
          icon: (props) => <MdFileDownload {...props} />,
          newState: {},
        }}
        openPauseModal={(ratesIds) => {
          isBulkDeleteRef.current = true

          downloadRates({
            title: 'Multiple Rates',
            fileName: `MultipleRates_${moment().format('LL HH:mm:ss').replace(/,/, '')}`,
            rates: totalRates.filter((rate) => ratesIds.includes(rate._id)),
          })
        }}
        deleteRequest={requestDeleteRate}
        addHeight={-30}
      />
    </RulesContainer>
  )
}

export default HistoryLanes
